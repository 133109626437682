<template>
  <div class="RepreneurAccueilRegistration" >
    <div class="row">
      <div class="col-12 col-lg-6 offset-lg-3">
        <div class="gavc-card gavc-text-center gavc-padding-large">
          <div class="gavc-card__header">
            <h3 class="gavc-h3 gavc-margin-bottom"> Vous reprenez une entreprise ? </h3>
            <p class="gavc-question gavc-margin-bottom">
              Garance à vos côtés vous accompagne dans votre projet de reprise,
              de la recherche d’entreprises à la contractualisation.
            </p>
         </div>
          <div class="gavc-card__content">
            <div class="text-left">
              <div class="gavc-field">
                <div class="gavc-radio-checkbox">
                  <div class="gavc-radio">
                    <input :value="false" type="radio" id="hasIdentifyCompany-input-2"
                           name="hasIdentifyCompany-input-2" v-model="hasIdentifyCompany">
                    <label class="gavc-label" for="hasIdentifyCompany-input-2">
                      Je cherche une entreprise à reprendre
                      <i class="gavc-icon gavc-icon-check" ></i>
                    </label>

                    <br>

                    <input :value="true" type="radio" id="hasIdentifyCompany-input-1"
                           name="hasIdentifyCompany-input-1" v-model="hasIdentifyCompany">
                    <label class="gavc-label" for="hasIdentifyCompany-input-1">
                      J'ai déjà identifié une entreprise à reprendre
                      <i class="gavc-icon gavc-icon-check" ></i>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <button
              class="gavc-btn"
              @click="goToRegistration"
              >
                C'est parti !
            </button>
         </div>
         <div class="gavc-card__footer gavc-margin-top">
             <p class="gavc-legende gavc-margin-top">
               Que vous repreniez une entreprise ou souhaitez racheter les parts d’une société, <br>
               Garance à vos côtés vous accompagne tout au long de votre projet.
             </p>
          </div>
          <div class="gavc-background-card-text">
            <p class="gavc-background-card-text__content"> reprise </p>
          </div>
        </div>

        <p class="gavc-p mt-3">
          Vous souhaitez céder votre entreprise ? 
          <router-link to="/inscription/accueil/cedant">
            <i class="gavc-icon gavc-icon-arrow-right" ></i> C'est par ici
          </router-link>
        </p>

      </div>
    </div>
  </div>
</template>

<script>
import { createHelpers } from 'vuex-map-fields';

const { mapFields } = createHelpers({
  mutationType: 'updateFieldWithLocalStorage',
});


export default {
  name: 'RepreneurAccueilRegistration',

  computed: {
    ...mapFields([
      'repreneurRegistrationStep',
      'repreneur.hasIdentifyCompany',
    ]),
  },

  methods: {
    goToRegistration () {
      this.repreneurRegistrationStep = 1
      if (this.hasIdentifyCompany) {
        this.$router.push('/inscription/repreneur')
      }
      else {
        this.$router.push('/offres-de-cession/recherche')
      }
    }
  }
}
</script>
